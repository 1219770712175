import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Арбитражный суд | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Представительство в арбитражном процессе требует серьезной квалификации от лиц, являющихся участниками спора" />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/arbitrazhnyj-sud'
				name="Арбитражный суд | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Представительство в арбитражном процессе требует серьезной квалификации от лиц, являющихся участниками спора"
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Арбитражный суд" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Арбитражный суд"}
			
				html={

				`

				<p class="TextText">
				Арбитражный суд - это постоянно действующий официальный государственный орган, рассматривающий хозяйственные споры между организациями и выносящий по ним решения. Арбитражный суд рассматривает в основном споры по договорным отношениям и соблюдение вытекающих обязательств. Объектом рассмотрения арбитражных судов являются также внешнеторговые и другие международные экономические связи. Решения арбитражного суда окончательные и не подлежат обжалованию.
				</p>

				<p class="TextText">
				Представительство в арбитражном процессе требует серьезной квалификации от лиц, являющихся участниками спора. Защита интересов в арбитражном суде предполагает наличие очень высокого уровня юридической и экономической грамотности участников процесса, поэтому без помощи компетентного специалиста, владеющего всеми тонкостями процессуального законодательства в суде обойтись крайне сложно. Именно по этой причине законодатель и ввел в отечественном судопроизводстве такой институт права как «представительство в суде», зная, что большинству участников спора (юридических лиц) обязательно понадобятся профессиональная помощь судебных представителей из числа квалифицированных юристов.
				</p>

				<p class="TextText">
				Мы обеспечиваем представительство в арбитражном суде любой сложности. На этапе рассмотрения дела наши специалисты проведут комплексную оценку, составят все необходимые документы и выступят в защиту Ваших интересов непосредственно на судебном слушании, а также в дальнейшем примут участие в исполнении вынесенных судом решений на практике, то есть доведут до логического завершения решение по ранее возникшему спору.
				</p>


				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
